<template>
  <div class="angToRad">
    <!-- <div class="background">

        </div> -->
    <div class="container">
      <div class="body">
        <div class="input_area">
          <ul>
            <li style="padding: 5px"></li>

            <li style="padding: 5px">
              <div class="lunar-converter">
                <el-row class="input-row">
                  <label class="label"
                    >姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</label
                  >
                  <el-input
                    v-model="name"
                    type="text"
                    label="姓名"
                    placeholder="请输入姓名"
                    clearable
                    @change="handleNameChange"
                    @clear="handleNameClear"
                    :style="{ background: 'rgba(255, 255, 255, 1)' }"
                    :class="{ 'el-input__inner--right': true }"
                  />
                  <el-select
                    v-model="gender"
                    placeholder="性别"
                    :style="{ marginLeft: '10px' }"
                  >
                    <el-option label="男" value="男"></el-option>
                    <el-option label="女" value="女"></el-option>
                  </el-select>
                </el-row>
              </div>

              <div class="lunar-converter">
                <el-radio-group
                  v-model="dateType"
                  size="small"
                  style="padding-bottom: 15px"
                >
                  <el-radio-button label="1">选择阳历</el-radio-button>
                  <el-radio-button label="2">选择阴历</el-radio-button>
                </el-radio-group>
              </div>

              <div class="lunar-converter" v-if="dateType === '1'">
                <el-row class="input-row">
                  <label class="label">阳历年份：</label>
                  <el-select v-model="year" placeholder="阳历年份">
                    <el-option
                      v-for="selectedYear in years"
                      :key="selectedYear"
                      :value="selectedYear"
                      :label="`${selectedYear}年`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">阳历月份：</label>
                  <el-select v-model="month" placeholder="阳历月份">
                    <el-option
                      v-for="selectedMonth in months"
                      :key="selectedMonth"
                      :value="selectedMonth"
                      :label="`${selectedMonth}月`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">阳历日期：</label>
                  <el-select v-model="day" placeholder="阳历日期">
                    <el-option
                      v-for="selectedDay in days"
                      :key="selectedDay"
                      :value="selectedDay"
                      :label="`${selectedDay}日`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">小时：</label>
                  <el-select v-model="hour" placeholder="选择小时">
                    <el-option
                      v-for="selectedHour in hours"
                      :key="selectedHour"
                      :value="selectedHour"
                      :label="`${selectedHour}时`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">分钟：</label>
                  <el-select v-model="minute" placeholder="选择分钟">
                    <el-option
                      v-for="i in 60"
                      :key="i - 1"
                      :label="i - 1"
                      :value="i - 1"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">出&nbsp;&nbsp;生&nbsp;&nbsp;地：</label>
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="selectedOptions"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-row>

                <!-- <el-button plain style="margin-left: 10px;" type="warning" @click="handleDateConfirm">
                                    计算阴历
                                </el-button> -->
              </div>
            </li>

            <li style="padding: 5px">
              <div class="lunar-converter" v-if="dateType === '2'">
                <el-row class="input-row">
                  <label class="label">阴历年份：</label>
                  <el-select
                    filterable
                    v-model="lunarYear"
                    placeholder="阴历年份"
                  >
                    <el-option
                      v-for="selectedYear in years"
                      :key="selectedYear"
                      :value="selectedYear"
                      :label="`${selectedYear}年`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">阴历月份：</label>
                  <el-select
                    v-model="lunarMonth"
                    placeholder="阴历月份"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="selectedMonth in months2"
                      :key="selectedMonth"
                      :value="selectedMonth"
                      :label="`${selectedMonth}月`"
                    ></el-option>
                  </el-select>
                  <el-checkbox v-model="isLeapMonth" style="margin-left: 10px"
                    >闰月</el-checkbox
                  >
                </el-row>

                <el-row class="input-row">
                  <label class="label">阴历日期：</label>
                  <el-select v-model="lunarDay" placeholder="阴历日期">
                    <el-option
                      v-for="selectedDay in days2"
                      :key="selectedDay"
                      :value="selectedDay"
                      :label="`${selectedDay}日`"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">小时：</label>
                  <el-select v-model="hour2" placeholder="选择小时">
                    <el-option
                      v-for="selectedHour in hours"
                      :key="selectedHour"
                      :value="selectedHour"
                      :label="`${selectedHour}时`"
                    ></el-option>
                  </el-select>
                </el-row>
                <el-row class="input-row">
                  <label class="label">分钟：</label>
                  <el-select v-model="minute2" placeholder="选择分钟">
                    <el-option
                      v-for="i in 60"
                      :key="i - 1"
                      :label="i - 1"
                      :value="i - 1"
                    ></el-option>
                  </el-select>
                </el-row>

                <el-row class="input-row">
                  <label class="label">出&nbsp;&nbsp;生&nbsp;&nbsp;地：</label>
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="selectedOptions"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-row>
                <el-row class="input-row">
                  <!-- <el-button plain type="warning" @click="convertLunarToSolar">计算阳历</el-button> -->
                </el-row>
              </div>
            </li>

            <li>
              <div class="list_item">
                <!-- <div class="label">出生日期(阳历)</div>
                                
                                <div v-if="birthday_solar" class="value" @click="handleModalShow">
                                    {{birthday_solar}}
                                </div>
                                <div v-else class="value lighter" @click="handleModalShow">
                                    请选择出生日期
                                </div> -->

                <div
                  class="list_btn"
                  hover-class="hover"
                  hover-stay-time="30"
                  @click="sureToCalc"
                >
                  开始测算八字
                </div>
              </div>
            </li>
            <li></li>
          </ul>
        </div>

        <div class="show_area basic_info">
          <div class="title">
            <img src="./images/icon_book.png" />
            基本信息
          </div>
          <div>
            <ul>
              <table>
                <tr>
                  <th class="col1">姓名</th>
                  <td class="col2" colspan="2">{{ personInfo.name }}</td>
                  <th class="col3">性别</th>
                  <td class="col4">
                    <span v-if="gender === '男'">乾造【男】</span>
                    <span v-else-if="gender === '女'">坤造【女】</span>
                    <span v-else>--</span>
                  </td>
                </tr>
                <tr>
                  <th class="col1">出生日期</th>
                  <td colspan="2">{{ personInfo.birthday_solar }}</td>
                  <th class="col3">生肖</th>
                  <td class="col4">{{ shengXiao }}</td>
                </tr>
                <tr>
                  <th class="col1">农历</th>
                  <td colspan="4">{{ personInfo.birthday_lunar }}</td>
                </tr>
                <tr>
                  <th class="col1">出生坐标</th>
                  <td class="col2" colspan="2">{{ address.longitude }}</td>
                  <td class="col4" colspan="2">{{ address.latitude }}</td>
                </tr>

                <tr>
                  <th class="col1"></th>
                  <th class="col2" @click="updateShiShen('年')">年柱</th>
                  <th class="col3" @click="updateShiShen('月')">月柱</th>
                  <th class="col4" @click="updateShiShen('日')">日柱</th>
                  <th class="col5" @click="updateShiShen('时')">时柱</th>
                </tr>
                <tr>
                  <th class="col1">天干</th>
                  <td class="col2 fontColor">
                    <span v-if="bazi.nianzhu.tg === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="top-start"
                        offset="50"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.nianZhu.tianGan.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.nianZhu.tianGan.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.nianZhu.tianGan.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.tianGan.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.nianZhu.tianGan.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.tianGan.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.nianzhu.tg }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.nianZhu.tianGan.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col3 fontColor">
                    <span v-if="bazi.yuezhu.tg === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="top-start"
                        offset="50"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.yueZhu.tianGan.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.yueZhu.tianGan.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.yueZhu.tianGan.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.tianGan.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.yueZhu.tianGan.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.tianGan.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.yuezhu.tg }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.yueZhu.tianGan.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col4 fontColor">
                    <span v-if="bazi.rizhu.tg === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="top-start"
                        offset="50"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.riZhu.tianGan.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.riZhu.tianGan.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.riZhu.tianGan.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.tianGan.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.riZhu.tianGan.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.tianGan.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.rizhu.tg }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.riZhu.tianGan.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col5 fontColor">
                    <span v-if="bazi.shizhu.tg === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="top-start"
                        offset="50"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.shiZhu.tianGan.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.tianGan.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.shiZhu.tianGan.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.shiZhu.tianGan.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.tianGan.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.shiZhu.tianGan.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.tianGan.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.shizhu.tg }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.shiZhu.tianGan.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="col1">地支</th>
                  <td class="col2 fontColor">
                    <span v-if="bazi.nianzhu.dz === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.nianZhu.diZhi.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.nianZhu.diZhi.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.nianZhu.diZhi.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.diZhi.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.nianZhu.diZhi.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.nianZhu.diZhi.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.nianzhu.dz }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.nianZhu.diZhi.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col3 fontColor">
                    <span v-if="bazi.yuezhu.dz === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.yueZhu.diZhi.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.yueZhu.diZhi.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.yueZhu.diZhi.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.diZhi.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.yueZhu.diZhi.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.yueZhu.diZhi.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.yuezhu.dz }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.yueZhu.diZhi.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col4 fontColor">
                    <span v-if="bazi.rizhu.dz === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.riZhu.diZhi.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.riZhu.diZhi.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.riZhu.diZhi.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.diZhi.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.riZhu.diZhi.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.riZhu.diZhi.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.rizhu.dz }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.riZhu.diZhi.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                  <td class="col5 fontColor">
                    <span v-if="bazi.shizhu.dz === '--'">--</span>
                    <span v-else>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="customPopper"
                      >
                        <div style="text-align: center">
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            {{ baZiWuXing.shiZhu.diZhi.wuXingSheng }}
                          </p>
                          <p>
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.diZhi.wuXing
                            }}</span>
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            {{ baZiWuXing.shiZhu.diZhi.wuXingKe }}
                          </p>
                          <p>
                            {{
                              baZiWuXing.shiZhu.diZhi.shengWuXing
                            }}
                            &nbsp;-&nbsp;生&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.diZhi.wuXing
                            }}</span>
                          </p>
                          <p>
                            {{
                              baZiWuXing.shiZhu.diZhi.keWuXing
                            }}
                            &nbsp;-&nbsp;克&nbsp;-&nbsp;
                            <span style="font-weight: bold">{{
                              baZiWuXing.shiZhu.diZhi.wuXing
                            }}</span>
                          </p>
                        </div>
                        <span slot="reference">
                          {{ bazi.shizhu.dz }}
                          <span style="font-size: 14px; color: black"
                            >({{ baZiWuXing.shiZhu.diZhi.wuXing }})</span
                          >
                        </span>
                      </el-popover>
                    </span>
                  </td>
                </tr>

                <tr>
                  <th class="col1">天干十神({{ shiShenType }})</th>
                  <td class="col2">{{ shiShen.nianShen }}</td>
                  <td class="col3">{{ shiShen.yueShen }}</td>
                  <td class="col4">{{ shiShen.riShen }}</td>
                  <td class="col5">{{ shiShen.shiShen }}</td>
                </tr>
                <tr>
                  <th class="col1">地支十神({{ shiShenType }})</th>
                  <td class="col2">{{ shiShen.nianShen_DiZhi }}</td>
                  <td class="col3">{{ shiShen.yueShen_DiZhi }}</td>
                  <td class="col4">{{ shiShen.riShen_DiZhi }}</td>
                  <td class="col5">{{ shiShen.shiShen_DiZhi }}</td>
                </tr>
                <tr>
                  <th class="col1">纳音</th>
                  <td class="col2">{{ naYin.nianYin }}</td>
                  <td class="col3">{{ naYin.yueYin }}</td>
                  <td class="col4">{{ naYin.riYin }}</td>
                  <td class="col5">{{ naYin.shiYin }}</td>
                </tr>

                <tr>
                  <th class="col1">藏干</th>
                  <td class="col2">
                    <p v-for="(item, index) in cangGan.nianGan" :key="index">
                      {{ item }}
                    </p>
                  </td>
                  <td class="col3">
                    <p v-for="(item, index) in cangGan.yueGan" :key="index">
                      {{ item }}
                    </p>
                  </td>
                  <td class="col4">
                    <p v-for="(item, index) in cangGan.riGan" :key="index">
                      {{ item }}
                    </p>
                  </td>
                  <td class="col5">
                    <p v-for="(item, index) in cangGan.shiGan" :key="index">
                      {{ item }}
                    </p>
                  </td>
                </tr>

                <tr>
                  <th class="col1">长生诀</th>
                  <td class="col2">{{ changSheng.nianSheng }}</td>
                  <td class="col3">{{ changSheng.yueSheng }}</td>
                  <td class="col4">{{ changSheng.riSheng }}</td>
                  <td class="col5">{{ changSheng.shiSheng }}</td>
                </tr>

                <tr>
                  <th class="col1">属性</th>
                  <td class="col2">{{ wuxing.nz }}</td>
                  <td class="col3">{{ wuxing.yz }}</td>
                  <td class="col4">{{ wuxing.rz }}</td>
                  <td class="col5">{{ wuxing.sz }}</td>
                </tr>
                <tr>
                  <th class="col1">属性个数</th>
                  <td colspan="4">{{ wuxingInfo }}</td>
                </tr>

                <tr>
                  <!-- <th class="col1">大运</th> -->
                  <td colspan="5">
                    <div class="table-container">
                      <table v-if="daYun.length != 0" class="dayun">
                        <tr>
                          <th>次数</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            第{{ key + 1 }}次大运
                          </td>
                        </tr>
                        <tr>
                          <th>起始年龄</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.startBirYear }}岁<span
                              v-if="item.startBirMonth != 0"
                              >{{ item.startBirMonth }}个月</span
                            >
                            <br />
                            ~ <br />
                            {{ item.endBirYear }}岁<span
                              v-if="item.endBirMonth != 0"
                              >{{ item.endBirMonth }}个月</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th>起始时间</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.startYear }}年{{ item.startMonth }}月 <br />
                            ~ <br />
                            {{ item.endYear }}年{{ item.endMonth }}月
                          </td>
                        </tr>
                        <tr>
                          <th>干支</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunGanZhi }}
                          </td>
                        </tr>
                        <tr>
                          <th>天干十神({{ shiShenType }})</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunShiShen }}
                          </td>
                        </tr>
                        <tr>
                          <th>地支十神({{ shiShenType }})</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunShiShen_diZhi }}
                          </td>
                        </tr>
                        <tr>
                          <th>纳音</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunNaYin }}
                          </td>
                        </tr>
                        <tr>
                          <th>长生</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunChangSheng }}
                          </td>
                        </tr>
                        <tr>
                          <th>禄神</th>
                          <td v-for="(item, key) in daYun" :key="key">
                            {{ item.daYunLu === "" ? "--" : item.daYunLu }}
                          </td>
                        </tr>
                      </table>
                      <p v-else>--</p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th rowspan="3" class="col1">流年</th>
                  <td rowspan="2" colspan="2">
                    <div style="width: 60%; margin: 0 auto; padding: 5px">
                      <el-select
                        size="mini"
                        @change="changeLiuNian"
                        v-model="liuNianYear"
                        placeholder="流年"
                      >
                        <el-option
                          v-for="item in liuNianOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <p>{{ liuNian }}</p>
                  </td>
                  <th>十神</th>
                  <td>
                    <span>{{ liuNian_shiShen }}</span>
                  </td>
                </tr>
                <tr>
                  <th>纳音</th>
                  <td>
                    <span>{{ liuNian_naYin }}</span>
                  </td>
                </tr>
                <!-- 近6年流年 -->
                <tr>
                  <td colspan="4">
                    <table v-if="sexLiuNian.length != 0" class="dayun">
                      <tr>
                        <th>年份</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.year }}
                        </td>
                      </tr>
                      <tr>
                        <th>干支</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.ganZhi }}
                        </td>
                      </tr>
                      <tr>
                        <th>天干十神({{ shiShenType }})</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.shiShen }}
                        </td>
                      </tr>
                      <tr>
                        <th>地支十神({{ shiShenType }})</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.shiShen_diZhi }}
                        </td>
                      </tr>
                      <tr>
                        <th>纳音</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.naYin }}
                        </td>
                      </tr>
                      <tr>
                        <th>禄神</th>
                        <td v-for="(item, key) in sexLiuNian" :key="key">
                          {{ item.lu === "" ? "--" : item.lu }}
                        </td>
                      </tr>
                    </table>
                    <p v-else>--</p>
                  </td>
                </tr>

                <!-- 组合 -->
                <tr>
                  <th>组合</th>
                  <td colspan="4">
                    <table v-if="currentDaYun != null">
                      <tr class="zuhe-tr">
                        <th>{{ liuNian }}年</th>
                        <th>年柱</th>
                        <th>月柱</th>
                        <th>日柱</th>
                        <th>时柱</th>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>干支</th>
                        <td>{{ bazi.nianzhu.tg }}{{ bazi.nianzhu.dz }}</td>
                        <td>{{ bazi.yuezhu.tg }}{{ bazi.yuezhu.dz }}</td>
                        <td>{{ bazi.rizhu.tg }}{{ bazi.rizhu.dz }}</td>
                        <td>{{ bazi.shizhu.tg }}{{ bazi.shizhu.dz }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>十神</th>
                        <td>{{ shiShen.nianShen }}</td>
                        <td>{{ shiShen.yueShen }}</td>
                        <td>{{ shiShen.riShen }}</td>
                        <td>{{ shiShen.shiShen }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>纳音</th>
                        <td>{{ naYin.nianYin }}</td>
                        <td>{{ naYin.yueYin }}</td>
                        <td>{{ naYin.riYin }}</td>
                        <td>{{ naYin.shiYin }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>长生</th>
                        <td>{{ changSheng.nianSheng }}</td>
                        <td>{{ changSheng.yueSheng }}</td>
                        <td>{{ changSheng.riSheng }}</td>
                        <td>{{ changSheng.shiSheng }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>大运-干支</th>
                        <td>{{ currentDaYun.daYunGanZhi }}</td>
                        <td>{{ currentDaYun.daYunGanZhi }}</td>
                        <td>{{ currentDaYun.daYunGanZhi }}</td>
                        <td>{{ currentDaYun.daYunGanZhi }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>大运-天干十神({{ shiShenType }})</th>
                        <td>{{ currentDaYun.daYunShiShen }}</td>
                        <td>{{ currentDaYun.daYunShiShen }}</td>
                        <td>{{ currentDaYun.daYunShiShen }}</td>
                        <td>{{ currentDaYun.daYunShiShen }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>大运-地支十神({{ shiShenType }})</th>
                        <td>{{ currentDaYun.daYunShiShen_diZhi }}</td>
                        <td>{{ currentDaYun.daYunShiShen_diZhi }}</td>
                        <td>{{ currentDaYun.daYunShiShen_diZhi }}</td>
                        <td>{{ currentDaYun.daYunShiShen_diZhi }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>大运-纳音</th>
                        <td>{{ currentDaYun.daYunNaYin }}</td>
                        <td>{{ currentDaYun.daYunNaYin }}</td>
                        <td>{{ currentDaYun.daYunNaYin }}</td>
                        <td>{{ currentDaYun.daYunNaYin }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>大运-长生</th>
                        <td>{{ currentDaYun.daYunChangSheng }}</td>
                        <td>{{ currentDaYun.daYunChangSheng }}</td>
                        <td>{{ currentDaYun.daYunChangSheng }}</td>
                        <td>{{ currentDaYun.daYunChangSheng }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>流年-天干十神({{ shiShenType }})</th>
                        <td>{{ liuNian_shiShen }}</td>
                        <td>{{ liuNian_shiShen }}</td>
                        <td>{{ liuNian_shiShen }}</td>
                        <td>{{ liuNian_shiShen }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>流年-地支十神({{ shiShenType }})</th>
                        <td>{{ liuNian_shiShen_diZhi }}</td>
                        <td>{{ liuNian_shiShen_diZhi }}</td>
                        <td>{{ liuNian_shiShen_diZhi }}</td>
                        <td>{{ liuNian_shiShen_diZhi }}</td>
                      </tr>
                      <tr class="zuhe-tr">
                        <th>流年-纳音</th>
                        <td>{{ liuNian_naYin }}</td>
                        <td>{{ liuNian_naYin }}</td>
                        <td>{{ liuNian_naYin }}</td>
                        <td>{{ liuNian_naYin }}</td>
                      </tr>
                    </table>
                    <p v-else>--</p>
                  </td>
                </tr>

                <tr>
                  <th class="col1">神煞</th>
                  <td colspan="4">
                    <p>{{ shenSha }}</p>
                  </td>
                </tr>

                <tr>
                  <th class="col1">解读</th>
                  <td
                    class="col1"
                    colspan="4"
                    v-if="baZiInfo.nianInfo === '--'"
                  >
                    --
                  </td>
                  <td v-else style="padding: 5px" colspan="4">
                    <p class="aligned-text indented-text">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ baZiInfo.nianInfo }}
                    </p>
                    <br />
                    <p class="aligned-text indented-text">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.yueInfo
                      }}
                    </p>
                    <br />
                    <p class="aligned-text indented-text">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.riInfo
                      }}
                    </p>
                    <br />
                    <p class="aligned-text indented-text">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.shiInfo
                      }}
                    </p>
                    <!-- 合 局 -->
                    <br v-if="baZiInfo.huaHe !== ''" />
                    <p
                      v-if="baZiInfo.huaHe !== ''"
                      class="aligned-text indented-text"
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.huaHe
                      }}
                    </p>
                    <br v-if="baZiInfo.ju !== ''" />
                    <p
                      v-if="baZiInfo.ju !== ''"
                      class="aligned-text indented-text"
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.ju
                      }}
                    </p>

                    <!-- 禄 -->
                    <br v-if="baZiInfo.nianLu !== ''" />
                    <p class="aligned-text indented-text">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span v-if="baZiInfo.nianLu !== ''">{{
                        baZiInfo.nianLu
                      }}</span>
                      <span v-if="baZiInfo.yueLu !== ''">{{
                        baZiInfo.yueLu
                      }}</span>
                      <span v-if="baZiInfo.riLu !== ''">{{
                        baZiInfo.riLu
                      }}</span>
                      <span v-if="baZiInfo.shiLu !== ''">{{
                        baZiInfo.shiLu
                      }}</span>
                    </p>
                    <p
                      v-if="baZiInfo.gongLu !== ''"
                      class="aligned-text indented-text"
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.gongLu
                      }}
                    </p>
                    <p
                      v-if="baZiInfo.daYunLu !== ''"
                      class="aligned-text indented-text"
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        baZiInfo.daYunLu
                      }}
                    </p>
                  </td>
                </tr>
              </table>
            </ul>
          </div>
        </div>

        <!-- <div class="show_area chart_analysis">
                    <div class="title">
                        <img src="./images/icon_table.png" />
                        五行强度
                    </div>
                    <div class="echart_container">
                        <mpvue-echarts :echarts="echarts" :onInit="initChart" />
                        <div v-if="!wuxingLists.length" class="emptyLay">
                        <img src="./images/img_empty.png" alt="" />
                        </div>
                    </div>
                </div> -->

        <!-- <div class="popup_area">
                    <el-dialog
                        :visible="isModalShow"
                        :close-on-click-overlay="false"
                        @close="handleModalHide"
                        >
                        <el-row class="input-row">
                            <el-col :span="6" class="input-col">
                                <label class="label">阳历年份</label>
                                <el-input v-model="year" placeholder="阳历年份"></el-input>
                            </el-col>
                            <el-col :span="6" class="input-col">
                                <label class="label">阳历月份</label>
                                <el-input v-model="month" placeholder="阳历月份"></el-input>
                            </el-col>
                            <el-col :span="6" class="input-col">
                                <label class="label">阳历日期</label>
                                <el-input v-model="day" placeholder="阳历日期"></el-input>
                            </el-col>
                            <el-col :span="6" class="input-col">
                                <label class="label">小时</label>
                                <el-input v-model="hour" placeholder="小时"></el-input>
                            </el-col>
                        </el-row>
                        <el-button slot="footer" type="primary" @click="handleDateConfirm">
                            确定
                        </el-button>
                    </el-dialog>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Lunar from "./Lunar";
import BaZi from "./Bazi";
import { formatHour } from "../../utils/index.js";
import Validator from "../../utils/validator";
import * as echarts from "echarts/dist/echarts";
import mpvueEcharts from "mpvue-echarts";
import axios from "axios";
import { regionData } from "element-china-area-data";
let chart = null;

const request = axios.create({
  baseURL: "https://scbz.fe888.cn",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  components: { mpvueEcharts },
  data() {
    return {
      options: regionData,
      selectedOptions: [],
      selectAddressCode: "",
      echarts,
      name: "",
      gender: "",
      chooseDate: "",
      birthday_solar: "",
      isModalShow: false,
      dateType: "1",
      year: "",
      month: "",
      day: "",
      hour: "",
      minute: "",
      minDate: new Date("1901/01/01").getTime(),
      maxDate: new Date("2049/12/30").getTime(),
      personInfo: {
        name: "--",
        birthday_solar: "--",
        birthday_lunar: "--",
        bazi: "--",
        wuxing: "--",
        fangwei: "--",
        shengxiao: "--",
        xingzuo: "--",
      },
      wuxingLists: [],
      lunarYear: "",
      lunarMonth: "",
      isLeapMonth: false,
      lunarDay: "",
      hour2: "",
      minute2: "",
      solarDate: "",
      shiShenType: "日",
      // 拆分八字
      bazi: {
        nianzhu: {
          tg: "--",
          dz: "--",
        },
        yuezhu: {
          tg: "--",
          dz: "--",
        },
        rizhu: {
          tg: "--",
          dz: "--",
        },
        shizhu: {
          tg: "--",
          dz: "--",
        },
      },
      //拆分五行
      wuxing: {
        nz: "--",
        yz: "--",
        rz: "--",
        sz: "--",
      },
      // 变通星 十神
      shiShen: {
        nianShen: "--",
        yueShen: "--",
        riShen: "--",
        shiShen: "--",
      },
      // 藏干
      cangGan: {
        nianGan: "--",
        yueGan: "--",
        riGan: "--",
        shiGan: "--",
      },
      // 纳音
      naYin: {
        nianYin: "--",
        yueYin: "--",
        riYin: "--",
        shiYin: "--",
      },
      changSheng: {
        nianSheng: "--",
        yueSheng: "--",
        riSheng: "--",
        shiSheng: "--",
      },
      address: {
        longitude: "--", // 经度
        latitude: "--", // 纬度
      },
      wuxingInfo: "--",
      years: [],
      months: [],
      months2: [],
      days: [],
      days2: [],
      hours: [],
      // 大运
      daYun: [],
      // 神煞
      shenSha: "--",
      // 流年
      liuNian: "--",
      liuNian_shiShen: "--",
      liuNian_shiShen_diZhi: "--",
      liuNian_naYin: "--",
      // 生肖
      shengXiao: "--",
      // 选中流年
      liuNianYear: null,
      // 流年下拉框
      liuNianOptions: [],
      // 近6年流年
      sexLiuNian: [],
      // 寓意
      baZiInfo: {
        nianInfo: "--",
        yueInfo: "",
        riInfo: "",
        shiInfo: "",
        huaHe: "",
        ju: "",
      },
      // 当前大运
      currentDaYun: null,
      // 八字五行属性
      baZiWuXing: null,
    };
  },

  created() {
    this.populateYears();
    this.populateMonths();
    this.populateMonths2();
    this.populateDays();
    this.populateHours();
  },
  mounted() {
    this.initDate();
  },

  methods: {
    handleChange(value) {
      this.selectAddressCode = value[2];
    },
    initDate() {
      request
        .post("/scbz/getLocalDateTime", null)
        .then((response) => {
          this.lunarYear = response.data.lunarDate.year;
          this.lunarMonth = response.data.lunarDate.month;
          this.lunarDay = response.data.lunarDate.day;
          this.isLeapMonth = response.data.lunarDate.leap;

          this.year = response.data.solarDate.year;
          this.month = response.data.solarDate.month;
          this.day = response.data.solarDate.day;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 加载下拉框
    populateYears() {
      const startYear = 1900;
      const endYear = 2050;
      for (let year = startYear; year <= endYear; year++) {
        this.years.push(Number(year));
      }
    },
    populateMonths() {
      for (let month = 1; month <= 12; month++) {
        this.months.push(Number(month));
      }
    },
    populateMonths2() {
      for (let month = 1; month <= 12; month++) {
        this.months2.push(Number(month));
      }
    },
    populateDays() {
      this.updateDays();
    },
    populateHours() {
      for (let hour = 0; hour <= 23; hour++) {
        this.hours.push(Number(hour));
      }
    },
    updateDays() {
      const selectedMonth = parseInt(this.month);
      const daysInMonth = new Date(
        parseInt(this.month),
        selectedMonth,
        0,
      ).getDate();

      this.days = [];
      for (let day = 1; day <= daysInMonth; day++) {
        this.days.push(Number(day));
      }
    },
    updateDays2() {
      const selectedMonth = parseInt(this.lunarMonth);
      const daysInMonth = new Date(
        parseInt(this.lunarMonth),
        selectedMonth,
        0,
      ).getDate();

      this.days2 = [];
      for (let day = 1; day <= daysInMonth; day++) {
        this.days2.push(Number(day));
      }
    },

    handleNameChange(e) {
      this.name = e;
    },
    handleNameClear() {
      this.name = "";
    },

    handleModalShow() {
      this.isModalShow = true;
    },
    handleModalHide() {
      this.isModalShow = false;
    },
    handleDateCancel() {
      this.handleModalHide();
    },

    // 点击转阴历
    handleDateConfirm(e) {
      const year = this.year;
      const month = this.month;
      const day = this.day;
      const hour = this.hour;
      const minute = this.minute;
      // 将值转换为完整的时间格式
      this.chooseDate = new Date(year, month - 1, day, hour, minute);

      this.birthday_solar = formatHour(this.chooseDate);
      console.log("格式化之后", this.birthday_solar);
      this.getLunerDate(year, month, day, hour, minute);
      this.handleModalHide();
    },

    // 计算生辰八字
    processPersonInfo() {
      let info = BaZi.calc(this.chooseDate);
      let lunar = Lunar.calc(this.chooseDate);

      this.personInfo = {
        name: this.name,
        birthday_solar: this.birthday_solar,
        birthday_lunar: lunar.str,
        bazi: info.bazi,
        wuxing: info.wuxing,
        fangwei: info.fangwei,
        shengxiao: info.shengxiao,
        xingzuo: info.xingzuo,
      };
      this.sliceBazi(info);
      this.sliceWuxing(info.wuxing);
      this.processWuxingStrength();
    },

    processWuxingStrength() {
      let wuxing = this.personInfo.wuxing;
      let charObj = {};
      let wuxingArr = ["木", "水", "金", "土", "火"];
      let wuxingLists = [0, 0, 0, 0, 0];
      for (let i = 0; i < wuxing.length; i++) {
        let charAt = wuxing.charAt(i);
        if (charObj[charAt]) {
          charObj[charAt]++;
        } else {
          charObj[charAt] = 1;
        }
      }
      Object.keys(charObj).forEach((key) => {
        for (let i = 0; i < wuxingArr.length; i++) {
          if (wuxingArr[i] === key) {
            wuxingLists[i] = charObj[key];
          }
        }
      });
      this.wuxingLists = wuxingLists;
    },

    // 拆分八字
    sliceBazi(info) {
      this.bazi.nianzhu.tg = info.nz.slice(0, 1);
      this.bazi.nianzhu.dz = info.nz.slice(1);
      this.bazi.yuezhu.tg = info.yz.slice(0, 1);
      this.bazi.yuezhu.dz = info.yz.slice(1);
      this.bazi.rizhu.tg = info.rz.slice(0, 1);
      this.bazi.rizhu.dz = info.rz.slice(1);
      this.bazi.shizhu.tg = info.sz.slice(0, 1);
      this.bazi.shizhu.dz = info.sz.slice(1);
    },
    // 拆分五行
    sliceWuxing(wuxing) {
      let [attribute1, attribute2, attribute3, attribute4] = wuxing
        .split("、")
        .map((attribute) => attribute.trim());
      this.wuxing.nz = attribute1;
      this.wuxing.yz = attribute2;
      this.wuxing.rz = attribute3;
      this.wuxing.sz = attribute4;

      // 统计每个属性的数量
      let wuxingInfoCount = {
        jin: 0,
        mu: 0,
        shui: 0,
        huo: 0,
        tu: 0,
      };

      // 遍历属性，增加对应属性的数量
      [attribute1, attribute2, attribute3, attribute4].forEach((attribute) => {
        // 拆分两个字
        let [firstCharacter, secondCharacter] = attribute;

        if (firstCharacter === "金") {
          wuxingInfoCount.jin++;
        } else if (firstCharacter === "木") {
          wuxingInfoCount.mu++;
        } else if (firstCharacter === "水") {
          wuxingInfoCount.shui++;
        } else if (firstCharacter === "火") {
          wuxingInfoCount.huo++;
        } else if (firstCharacter === "土") {
          wuxingInfoCount.tu++;
        }

        if (secondCharacter === "金") {
          wuxingInfoCount.jin++;
        } else if (secondCharacter === "木") {
          wuxingInfoCount.mu++;
        } else if (secondCharacter === "水") {
          wuxingInfoCount.shui++;
        } else if (secondCharacter === "火") {
          wuxingInfoCount.huo++;
        } else if (secondCharacter === "土") {
          wuxingInfoCount.tu++;
        }
      });

      let result = "";
      result += `${wuxingInfoCount.jin}层金；`;
      result += `${wuxingInfoCount.mu}层木；`;
      result += `${wuxingInfoCount.shui}层水；`;
      result += `${wuxingInfoCount.huo}层火；`;
      result += `${wuxingInfoCount.tu}层土；`;
      this.wuxingInfo = result;
    },

    initChart(canvas, width, height) {
      chart = echarts.init(canvas, null, {
        // 注意:不要添加局部变量申明
        width: width,
        height: height,
      });
      canvas.setChart(chart);
      return chart;
    },

    setOption() {
      let option = {
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: ["#5abaff"],
        radar: {
          indicator: [
            { name: "木", max: 5 },
            { name: "水", max: 5 },
            { name: "金", max: 5 },
            { name: "土", max: 5 },
            { name: "火", max: 5 },
          ],
        },
        series: [
          {
            name: "五行强度",
            type: "radar",
            data: [
              {
                value: this.wuxingLists,
                name: "五行强度",
              },
            ],
          },
        ],
      };
      chart.setOption(option);
      // this.$nextTick(() => {
      //     chart.setOption(option);
      // });
    },

    vaildateFunc() {
      let validator = new Validator();
      validator.add(this.name, [
        {
          strategy: "isNonEmpty",
          errorMsg: "请输入姓名",
        },
      ]);
      validator.add(this.gender, [
        {
          strategy: "isNonEmpty",
          errorMsg: "请选择性别",
        },
      ]);

      if (this.dateType === "1") {
        if (this.minute !== 0) {
          validator.add(this.minute, [
            {
              strategy: "isNonEmpty",
              errorMsg: "出生日期请精确至分",
            },
          ]);
        }
      } else {
        if (this.minute2 !== 0) {
          validator.add(this.minute2, [
            {
              strategy: "isNonEmpty",
              errorMsg: "出生日期请精确至分",
            },
          ]);
        }
      }
      // validator.add(this.selectAddressCode, [{
      //     strategy: 'isNonEmpty',
      //     errorMsg: '请选择出生地点'
      // }]);

      validator.add(this.chooseDate, [
        {
          strategy: "isNonEmpty",
          errorMsg: "请选择出生日期",
        },
      ]);
      let errorMsg = validator.start();
      return errorMsg;
    },

    // 开始测算
    sureToCalc() {
      if (this.dateType === "1") {
        console.log("分钟" + this.minute);
        this.handleDateConfirm();
        let errorMsg = this.vaildateFunc();
        if (errorMsg) {
          this.$message({
            message: errorMsg,
            duration: 1000,
          });
          return false;
        } else {
          this.processPersonInfo();
          this.setOption();
        }
      }
      if (this.dateType === "2") {
        this.convertLunarToSolar();
      }
    },

    convertLunarToSolar() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("year", this.lunarYear);
      formData.append("month", this.lunarMonth);
      formData.append("day", this.lunarDay);
      formData.append("hour", this.hour2 ? this.hour2 : 0);
      formData.append("minute", this.minute2 ? this.minute2 : 0);
      formData.append("isLeap", this.isLeapMonth);
      formData.append("selectAddressCode", this.selectAddressCode);
      formData.append("gender", this.gender);

      request
        .post("/scbz/convertedSolarCalendar", formData)
        .then((response) => {
          this.LunarHandleDateConfirm(response.data.solarDate);
          let errorMsg = this.vaildateFunc();
          if (errorMsg) {
            this.$message({
              message: errorMsg,
              duration: 1000,
            });
            return false;
          } else {
            this.shiShen = response.data.shiShen;
            this.cangGan = response.data.cangGan;
            this.naYin = response.data.naYin;
            this.address.longitude = "经度：" + response.data.address.longitude;
            this.address.latitude = "纬度：" + response.data.address.latitude;
            this.daYun = response.data.daYun;
            this.shenSha = response.data.shenSha;
            this.liuNian = response.data.liuNian;
            this.liuNian_shiShen = response.data.liuNian_shiShen;
            this.liuNian_shiShen_diZhi = response.data.liuNian_shiShen_diZhi;
            this.liuNian_naYin = response.data.liuNian_naYin;
            this.shengXiao = response.data.shengXiao;
            this.liuNianYear = response.data.currentYear;
            this.liuNianOptions = response.data.liuNianOptions;
            this.sexLiuNian = response.data.sexLiuNian;
            this.changSheng = response.data.changSheng;
            this.baZiInfo = response.data.baZiInfo;
            this.currentDaYun = response.data.currentDaYun;
            this.baZiWuXing = response.data.baZiWuXing;
            this.processPersonInfo();
            this.setOption();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLunerDate(year, month, day, hour, minute) {
      let errorMsg = this.vaildateFunc();
      if (errorMsg) {
        return false;
      } else {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("year", year);
        formData.append("month", month);
        formData.append("day", day);
        formData.append("hour", hour);
        formData.append("minute", minute);
        formData.append("selectAddressCode", this.selectAddressCode);
        formData.append("gender", this.gender);

        request
          .post("/scbz/convertedCalendar", formData)
          .then((response) => {
            this.isLeapMonth = response.data.lunarDate.leap;
            this.lunarYear = response.data.lunarDate.year;
            this.lunarMonth = response.data.lunarDate.month;
            this.lunarDay = response.data.lunarDate.day;
            this.hour2 = response.data.lunarDate.hour;
            this.minute2 = response.data.lunarDate.minute;
            this.shiShen = response.data.shiShen;
            this.cangGan = response.data.cangGan;
            this.naYin = response.data.naYin;
            this.address.longitude =
              response.data.address.longitude === ""
                ? "--"
                : "经度：" + response.data.address.longitude;
            this.address.latitude =
              response.data.address.latitude === ""
                ? "--"
                : "纬度：" + response.data.address.latitude;
            this.daYun = response.data.daYun;
            this.shenSha = response.data.shenSha;
            this.liuNian = response.data.liuNian;
            this.liuNian_shiShen = response.data.liuNian_shiShen;
            this.liuNian_shiShen_diZhi = response.data.liuNian_shiShen_diZhi;
            this.liuNian_naYin = response.data.liuNian_naYin;
            this.shengXiao = response.data.shengXiao;
            this.liuNianYear = response.data.currentYear;
            this.liuNianOptions = response.data.liuNianOptions;
            this.sexLiuNian = response.data.sexLiuNian;
            this.changSheng = response.data.changSheng;
            this.baZiInfo = response.data.baZiInfo;
            this.currentDaYun = response.data.currentDaYun;
            this.baZiWuXing = response.data.baZiWuXing;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    // 流年的值发生了变化
    changeLiuNian(value) {
      const formData = new FormData();
      formData.append("year", value);
      formData.append(
        "nianGanZhi",
        this.bazi.nianzhu.tg + this.bazi.nianzhu.dz,
      );
      formData.append("yueGanZhi", this.bazi.yuezhu.tg + this.bazi.yuezhu.dz);
      formData.append("riGanZhi", this.bazi.rizhu.tg + this.bazi.rizhu.dz);
      formData.append("shiGanZhi", this.bazi.shizhu.tg + this.bazi.shizhu.dz);
      formData.append("birYear", this.year);
      formData.append("birMonth", this.month);
      formData.append("birDay", this.day);
      formData.append("birHour", this.hour ? this.hour : 0);
      formData.append("birMinute", this.minute ? this.minute : 0);
      formData.append("gender", this.gender);
      formData.append("shiShenType", this.shiShenType);
      request
        .post("/scbz/getLiuNianInfo", formData)
        .then((response) => {
          this.liuNian = response.data.liuNian;
          this.liuNian_shiShen = response.data.liuNian_shiShen;
          this.liuNian_shiShen_diZhi = response.data.liuNian_shiShen_diZhi;
          this.liuNian_naYin = response.data.liuNian_naYin;
          this.shenSha = response.data.shenSha;
          this.currentDaYun = response.data.currentDaYun;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 更新十神
    updateShiShen(type) {
      this.shiShenType = type;
      const formData = new FormData();
      formData.append("nianZhu", this.bazi.nianzhu.tg + this.bazi.nianzhu.dz);
      formData.append("yueZhu", this.bazi.yuezhu.tg + this.bazi.yuezhu.dz);
      formData.append("riZhu", this.bazi.rizhu.tg + this.bazi.rizhu.dz);
      formData.append("shiZhu", this.bazi.shizhu.tg + this.bazi.shizhu.dz);
      formData.append("shiShenType", type);
      formData.append("year", this.liuNianYear);
      formData.append("birthYear", this.year);
      formData.append("birthMonth", this.month);
      formData.append("birthDay", this.day);
      formData.append("birthHour", this.hour ? this.hour : 0);
      formData.append("birthMinute", this.minute ? this.minute : 0);
      formData.append("sex", this.gender);
      request
        .post("/scbz/getShiShenAndCangGan", formData)
        .then((response) => {
          // 十神
          this.shiShen = response.data.shiShen;
          // 藏干十神
          this.cangGan = response.data.cangGan;
          // 流年十神
          this.liuNian_shiShen = response.data.liuNian_shiShen;
          this.liuNian_shiShen_diZhi = response.data.liuNian_shiShen_diZhi;
          // 近六年十神
          this.sexLiuNian = response.data.sexLiuNian;
          // 大运
          this.daYun = response.data.daYun;
          // 组合
          this.currentDaYun = response.data.currentDaYun;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    LunarHandleDateConfirm(data) {
      this.year = data.year;
      this.month = data.month;
      this.day = data.day;
      this.hour = data.hour;
      this.minute = data.minute;
      // 将值转换为完整的时间格式
      this.chooseDate = new Date(
        this.year,
        this.month - 1,
        this.day,
        this.hour,
        this.minute,
      );
      this.birthday_solar = formatHour(this.chooseDate);
    },
  },
  watch: {
    year() {
      this.updateDays();
    },
    month() {
      this.updateDays();
    },
    lunarMonth() {
      this.updateDays2();
    },
  },
};
</script>

<style lang="less">
@import url("../../utils/theme.less");

.customPopper {
  min-width: 80px;
}
.zuhe-tr {
  font-size: 12px;
}
.aligned-text {
  text-align: left;
}

.indented-text {
  padding-left: 18px;
  padding-right: 10px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.dayun {
  font-size: 10px;
}

.lunar-converter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  width: 120px;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .label {
    width: 100%;
    margin-bottom: 5px;
  }
}

.fontColor {
  color: #a24b52;
  font-weight: bold;
}
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ebebeb;
}

th,
td {
  border: 1px solid #ebebeb;
  padding: 8px;
}

.col1 {
  width: 12%;
}

.col2,
.col3,
.col4,
.col5 {
  width: 22%;
}

.label {
  padding-left: 10px;
  width: 100px;
  white-space: nowrap;
}
.lunar-converter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.input-row {
  display: flex;
  align-items: center;
}

.input-col {
  margin-right: 10px;
}

.angToRad {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #efefef;
    img {
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  }
  .container {
    .header {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid @textLightColor;
      .title {
        text-align: center;
        color: @mainTitleColor;
        font-size: 18px;
        width: 100%;
      }
    }
    .body {
      padding: 10px 7.5px 0;
      .input_area {
        ul {
          width: 100%;
          li {
            width: 100%;
            background: #ffffff;
            padding: 5px;
            .list_item {
              display: flex;
              height: 33px;
              font-size: 14px;
              padding: 10px 15px;
              color: #333333;
              background: rgba(255, 255, 255, 1);
              .label {
                display: flex;
                flex: 2;
                align-items: center;
              }
              .value {
                display: flex;
                flex: 3;
                align-items: center;
                justify-content: flex-end;
                &.lighter {
                  color: #999999;
                }
              }
            }
            .list_btn {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
              border: 0px solid #000;
              border-radius: 5%;
              height: 33px;
              font-size: 16px;
              padding: 10px 15px;
              color: @myOrange;
              background: rgba(255, 130, 71, 0.1);
              &.hover {
                background: rgba(255, 130, 71, 0.3);
                color: #ffffff;
              }
            }
          }
        }
      }
      .show_area {
        margin-top: 10px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        padding: 0 15px;
        .title {
          width: 100%;
          height: 50px;
          display: flex;
          font-size: 16px;
          color: @mainTitleColor;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        &.basic_info {
          padding-bottom: 10px;
          ul {
            font-size: 15px;
            li {
              display: flex;
              height: 40px;
              align-items: center;
              &:not(:last-child) {
                border-bottom: 1px solid @baseColor;
              }
              .label {
                flex: 1;
                color: @textColor;
              }
              .value {
                flex: 3;
                color: @mainTitleColor;
              }
            }
          }
        }
        &.chart_analysis {
          .echart_container {
            position: relative;
            width: 100%;
            height: 300px;
            .emptyLay {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                display: block;
                width: 100px;
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
